import { ChangeEvent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ContactPropType } from './contact.types';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Alert, Spinner } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
const Contact=(props:ContactPropType)=>{
    const [contactState,setContactState]=useState({
        email:'',
        message:''
    });
    const [isLoading,setIsLoading]=useState(false)
    const [showSnackBar,setShowSnackBar]=useState(false)
    const onEmailTextChange=(e: any)=>{
        setContactState((p)=>{
            return {...p,email:e.target.value}
        })
    }
    const onMessageTextChange=(e: any)=>{
        setContactState((p)=>{
            return {...p,message:e.target.value}
        })
    }
    const onSubmit=()=>{
        console.log('submit')
        addDataFirestor()
     
    }
    const addDataFirestor=async ()=>{
        const emailsCollectionRef=collection(db,"emails");
        setIsLoading(true)
        try {
            const docRef = await addDoc(emailsCollectionRef,contactState);
            console.log("Document written with ID: ", docRef.id);
            toggleModal()
            resetData()
           // alert('Sent!')
           setShowSnackBar(true)
          } catch (e) {
            console.error("Error adding document: ", e);
            toggleModal()
            resetData()
          }
          setIsLoading(false)
    }
    const resetData=()=>{
        setContactState({email:'',message:''})
    }
    const {showModal,toggleModal}=props
    return (
      <>
        <Modal show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  autoFocus
                  onChange={onEmailTextChange}
                  value={contactState.email}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} onChange={onMessageTextChange}
                value={contactState.message}/>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Close
            </Button>
            {isLoading?<Spinner animation="border" />: <Button variant="primary" onClick={onSubmit}>
             Submit
            </Button>}
           
          </Modal.Footer>
        </Modal>
        <Snackbar
        anchorOrigin={{vertical:'top',horizontal:'right'}}
        open={showSnackBar}
        autoHideDuration={6000}
        message="Sent!"
      />
      </>
    );
}
export default Contact