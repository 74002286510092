// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
const firebaseConfig = {
  apiKey: "AIzaSyDxqjGkLjw_t-r4WqUFAXpw-Zx9l_oTAyw",
  authDomain: "bharatsuccessstory.firebaseapp.com",
  projectId: "bharatsuccessstory",
  storageBucket: "bharatsuccessstory.appspot.com",
  messagingSenderId: "700003998165",
  appId: "1:700003998165:web:a78f0950b7cd193b01c55c",
  measurementId: "G-CN7MPYZWVQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)
export const db=getFirestore(app)