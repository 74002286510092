
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { AddBlogPropType } from "./addBlog.types"
import styles from './addBlog.module.css'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const AddBlog=(props:AddBlogPropType)=>{
    const {changeAuthor,changePhoto,changeText,changeTitle,textValue,photoValue,titleValue,authorValue,addPost,
    visible,onDismiss}=props
    const footerContent = (
        <div style={{padding:"20px",justifyContent:"center",display:"flex"}}>
            <Button style={{width:"100px",height:"50px"}} label="Submit" icon="pi pi-check" onClick={addPost} autoFocus />
        </div>
    );

    return (
        <Dialog contentStyle={{padding:"20px"}}  headerStyle={{padding:"20px"}}  header="Add Blog" visible={visible} style={{ width: '50vw',border:'1px solid black',  height:'fit-content'}} onHide={onDismiss} footer={footerContent}>
           <div className={styles.inputContainer}>
           <p className={styles.p}>Photo:</p><input  type="text" className={styles.input} placeholder="Post's photo (direct URL)" onChange={changePhoto} value={photoValue}/>
           </div>
           <div className={styles.inputContainer}>
           <p className={styles.p}>Title:</p><input  type="text" className={styles.input} placeholder="Enter the title of your post" onChange={changeTitle} value={titleValue}/>
           </div>
           <div className={styles.inputContainer}>
           <p className={styles.p}>Description:</p>
           <textarea  className={styles.textarea} placeholder='Enter text of your post' rows={10} onChange={changeText} value={textValue}></textarea>
           </div>
           <div className={styles.inputContainer}>
           <p className={styles.p}>Author:</p><input  type="text" className={styles.input} placeholder="Enter your name" onChange={changeAuthor} value={authorValue}/>
           </div>
            </Dialog>
    )
}
export default AddBlog