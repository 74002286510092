import { useMutation } from "react-query"

export type BlogBlockDataType={
	level:number
	text:string 
}
export type BlogBlockType={
    id:string,
    data:BlogBlockDataType,
    type: string
}
export type BlogType = {
    title: string,
    author: string,
    createdAt: string,
    bannerurl: string,
    blocks:BlogBlockType[]
    description:string
}

export const getBlogData = async (): Promise<BlogType[]> => {
    const response = await fetch('https://bharat-success-story-backend-production.up.railway.app/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    console.log('response',response)
    if (!response.ok) {
        throw new Error('Something wen wrong. Please retry');
    }
    return await response.json();
};

export const useGetBlogData=()=>{
    return useMutation(getBlogData)
}
export const sendBlogData = async (data:BlogType): Promise<any> => {
    const response = await fetch('https://bharat-success-story-backend-production.up.railway.app/blog', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(data)
    });
    console.log('response',response)
    if (!response.ok) {
        throw new Error('Something wen wrong. Please retry');
    }
    return await response.json();
};

export const usePostBlogData=()=>{
    return useMutation(sendBlogData)
}