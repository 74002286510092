import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection:{
        order: ['htmlTag','htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'subdomain'],
        caches:['cookie']
    },
    backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json',
      },
  });


export default i18n;