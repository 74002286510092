import React from "react"
import styles from './blogEditor.module.css'
import NavHeader from "../../common/navHeader"
import AddBlog from "./components/addBlog"
import { BlogEditorPropType } from "./blogEditor.types"
const BlogEditorTemplate=(props:BlogEditorPropType)=>{
    const {changeAuthor,changePhoto,changeText,changeTitle,blogPostState,addPost,
    showAddBlogModal,
toggleAddBlogModal,onPublishButtonPressed}=props
    return <React.Fragment>
        <NavHeader/>
        <div className={styles.root}>
        <AddBlog
            changeAuthor={changeAuthor}
            changePhoto={changePhoto}
            changeText={changeText}
            changeTitle={changeTitle}
            textValue={blogPostState.textValue}
            photoValue={blogPostState.photoValue}
            titleValue={blogPostState.titleValue}
            authorValue={blogPostState.authorValue}
            addPost={addPost}
            visible={showAddBlogModal}
            onDismiss={toggleAddBlogModal}
          />
        <div onClick={onPublishButtonPressed}><a className={styles.btn} href="#">Publish</a></div>
            <div className={styles['editor-container']} id="text-editor"></div>
        </div>
    </React.Fragment>
}
export default BlogEditorTemplate