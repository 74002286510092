import React, { Component, useState } from 'react'
import './auth.css'
import NavBar from '../../common/components/navBar'
import { AuthType, InputType } from './Auth.container'
import { Alert, Spinner } from 'react-bootstrap'

type PropType={
  authMode: AuthType
  onChangeAuthType:()=>void
  onTextChange: (type: InputType, value: string) => void
  authData: {
    name: string;
    emailAddress: string;
    password: string;
}
onSubmitClicked: () => void
isLoading:boolean
}
const AuthView= (props:PropType) =>{
  const {authMode,onChangeAuthType,authData,onTextChange,onSubmitClicked,
  isLoading}=props
  if (authMode === AuthType.SIGN_IN) {
    return (
    <>
    <NavBar/>
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <span className="link-primary" onClick={onChangeAuthType}>
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                value={authData.emailAddress}
                onChange={(e)=>onTextChange(InputType.EMAIL,e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={authData.password}
                onChange={(e)=>onTextChange(InputType.PASSWORD,e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
           
              {isLoading?<Spinner style={{margin:'auto'}} animation="border" />:<button  onClick={onSubmitClicked} type="submit" className="btn btn-primary">
                Submit
              </button>}
            </div>
            <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </form>
      </div>
    </>
    )
  }

  return (
 <>
 <NavBar/>
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={onChangeAuthType}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="e.g Jane Doe"
              value={authData.name}
              onChange={(e)=>onTextChange(InputType.NAME,e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Email Address"
              value={authData.emailAddress}
              onChange={(e)=>onTextChange(InputType.EMAIL,e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={authData.password}
              onChange={(e)=>onTextChange(InputType.PASSWORD,e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
          {isLoading?<Spinner style={{margin:'auto'}} animation="border" />:<button  onClick={onSubmitClicked} type="submit" className="btn btn-primary">
                Submit
              </button>}
          </div>
          <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div></>
  )
}
export default AuthView;