import './storyCard.css'

type StoryCardProp={
  data:any
  onBlogClicked:()=>void
}
const StoryCard=(props:StoryCardProp)=>{
  const {data,onBlogClicked}=props;
  const { author,
  Date,
  title,
  tags,
  bannerUrl,
  subTitle,
  description,}=data
    //const imageUrl='https://storage.googleapis.com/chydlx/codepen/blog-cards/image-2.jpg'
    return   <div onClick={onBlogClicked} className="blog-card alt">
    <div className="meta">
      <div className="photo" style={{  backgroundImage: `url(${bannerUrl})`,}}></div>
      <ul className="details">
        <li className="author"><a href="#">{author}</a></li>
        <li className="date">{Date}</li>
        <li className="tags">
          <ul>
           {tags.map((item:any)=> <li><a href="#">{item}</a></li>)}
          </ul>
        </li>
      </ul>
    </div>
    <div className="description">
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <p>{description}</p>
      <p className="read-more">
        <a href="#">Read More</a>
      </p>
    </div>
  </div>
}
export default StoryCard