import { CircularProgress } from "@mui/material"
import Footer from "../../common/components/footer"
import NavBar from "../../common/components/navBar"
import StoryCard from "../../common/components/storyCard"
import styles from './story.module.css'
type StoryViewProps={
    userName: string | undefined
    data:any
    onBlogCLicked: (id: string) => void
    isLoading: boolean
}
const StoryView=(props:StoryViewProps)=>{
    const {userName,data,onBlogCLicked,isLoading}=props
    return <div className={styles.rootContainer}>
        <NavBar userName={userName}/>
       <div className={styles.blogContainer}>
        {isLoading?<CircularProgress style={{marginTop:'200px'}} color="secondary" />:undefined}
       {data?.map((item:any)=><StoryCard onBlogClicked={()=>onBlogCLicked(item.id)} data={item} />)}
       </div>
        {/* <Footer showLinkCOntainer={false}/> */}
    </div>
}
export default StoryView