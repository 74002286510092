import { useNavigate } from "react-router-dom";
import HomeTemplate from "./home.template";
import { BlogType, useGetBlogData } from "../../dataSources/queryHooks/home";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase";

const Home=()=>{
    const navigate = useNavigate();
    const [userName,setUserName]=useState<string | undefined>(undefined)
   useEffect(()=>{
    const user = auth.currentUser;
    console.log('user0',user)
    if(user!=null && user.displayName)
    {
        console.log('user',user)
        setUserName(user?.displayName)
    }
   },[])
   const navigatoStories=()=>{
    navigate('/stories')
   }
    return <HomeTemplate userName={userName}
    navigatoStories={navigatoStories}/>
}
export default Home;