import Output from 'editorjs-react-renderer';
import { useLocation, useParams } from 'react-router-dom';
import NavBar from '../../common/components/navBar';
import { useEffect, useState } from 'react';
import { auth } from '../../config/firebase';
import styles from './blog.module.css'
type StoryDetailProp={
    data:any
}
const StoryDetail=()=>{
    const {state} = useLocation();
    const { id } = useParams();
    const [userName,setUserName]=useState<string | undefined>(undefined)
    useEffect(()=>{
     const user = auth.currentUser;
     if(user!=null && user.displayName)
     {
         setUserName(user?.displayName)
     }
    },[])
    return <div>
        <NavBar userName={userName}/>
    <div style={{width:'50%',margin:'auto',paddingTop:"100px"}}>
    <h2 className={styles['blog-title']}>{state.data.title}</h2>
            <img className={styles['card-img']} src={state.data.bannerUrl} />
    {state.data.id===id?<Output  data={ state.data } />:<h1>Error 404</h1>}
    </div>
    </div>
}
export default StoryDetail