import Home from "./screens/home";
import './app.css'
import Blog from "./screens/blog";
import BlogEditor from "./screens/blogEditor";
import { Route, Routes } from "react-router-dom";
import Story from "./screens/story";
import "bootstrap/dist/css/bootstrap.min.css"
import Auth from "./screens/login";
import StoryDetail from "./screens/storyDetail";
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" Component={Home}/>
        <Route path="/stories" Component={Story}/>
        <Route path="/auth" Component={Auth}/>
        <Route path="/storyDetail/:id" Component={StoryDetail}/>
        <Route path="/blogEditor/5011326a-4432-491b-9d82-940d52485e49" Component={BlogEditor}/>
      </Routes>
    </div>
  );
}

export default App;
