import { useLocation, useNavigate } from "react-router-dom";
import AuthView from "./auth.template"
import { useEffect, useState } from "react";
import {  createUserWithEmailAndPassword, signInWithEmailAndPassword,updateProfile   } from 'firebase/auth';
import { auth } from "../../config/firebase";
import { Alert } from "react-bootstrap";
export enum AuthType{
SIGN_UP='SIGN_UP',
SIGN_IN='SIGN_IN'
}
export enum InputType{
    NAME="NAME",
    EMAIL='EMAIL',
    PASSWORD='PASSWORD'
}
const Auth=()=>{
    const { state } = useLocation();
    const navigate = useNavigate();
    const [authMode, setAuthMode] = useState<AuthType>(state?.authType??AuthType.SIGN_IN)
    const [authData,setAuthData]=useState({
        name:'',
        emailAddress:"",
        password:""
    })
    const [isLoading,setIsLoading]=useState(false);
    useEffect(()=>{
        setAuthMode(state?.authType??AuthType.SIGN_IN)
    },[state])

  const changeAuthMode = () => {
    setAuthMode(authMode === AuthType.SIGN_IN ? AuthType.SIGN_UP : AuthType.SIGN_IN)
  }
  const onSubmitClicked=()=>{
    onSubmit()
  }
  const onSubmit = async () => {
    if(authMode===AuthType.SIGN_UP)
    {
        setIsLoading(true)
        await createUserWithEmailAndPassword(auth, authData.emailAddress, authData.password)
        .then((userCredential) => {
            setIsLoading(false)
            const user = userCredential.user;
            const update = {
                displayName: authData.name,
              };
              if(user)
              {
                updateProfile(user,update);
              }
              showAlert('success','SignUp Success!!')
              navigate('/')
        })
        .catch((error) => {
            setIsLoading(false)
            const errorCode = error.code;
            const errorMessage = error.message;
            showAlert('danger',errorMessage)
            // ..
        });
    }
    else
    {
        setIsLoading(true)
    await signInWithEmailAndPassword(auth, authData.emailAddress, authData.password)
        .then((userCredential) => {
            showAlert('success','Logged In')
            setIsLoading(false)
            navigate('/')
        })
        .catch((error:any) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            showAlert('danger',errorMessage)
            setIsLoading(false)
        });
    }
  }
  const showAlert=(variant:string,message:string)=>{
    
  }
 
  const onTextChange=(type:InputType,value:string)=>{
    if(type==InputType.EMAIL)
    {
        setAuthData((p)=>{
            return {...p,emailAddress:value}
        })
    }
    else if(type==InputType.PASSWORD)
    {
        setAuthData((p)=>{
            return {...p,password:value}
        })
    }
    else if(type==InputType.NAME)
    {
        setAuthData((p)=>{
            return {...p,name:value}
        })
    }
  }

    return <AuthView isLoading={isLoading} onChangeAuthType={changeAuthMode} authMode={authMode}
    authData={authData} onTextChange={onTextChange} onSubmitClicked={onSubmitClicked}/>
}
export default Auth