import { useEffect, useRef, useState } from "react";
import BlogEditorTemplate from "./blogEditor.template";

import EditorJS from "@editorjs/editorjs"
import { EDITOR_JS_TOOLS } from "./components/tool/tool.container";
import { BlogPostStateType } from "./blogEditor.types";
import { BlogType, usePostBlogData } from "../../dataSources/queryHooks/home";
import { useNavigate, useNavigation } from "react-router-dom";

const BlogEditor=()=>{
    const ref = useRef<any>(null);
    const [blogPostState,setBlogPostState]=useState<BlogPostStateType>({
      photoValue:"",
      titleValue:"",
      textValue:"",
      authorValue:""
    })
    const [showAddBlogModal,setShowAddBlogModal]=useState(false);
    const {mutate,isLoading}=usePostBlogData()
    const navigate=useNavigate()
    useEffect(()=>{
        //Initialize editorjs if we don't have a reference
    if (!ref.current) {
        const editor = new EditorJS({
          holderId: 'text-editor',
          tools: EDITOR_JS_TOOLS,
          data: undefined,
        });
        ref.current = editor;
      }
  
      //Add a return function to handle cleanup
      return () => {
        if (ref.current && ref.current.destroy) {
          ref.current.destroy();
        }
      };
    },[])
    const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setBlogPostState((p)=>{
        return {...p,photoValue:newValue}
      })
    }
    const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setBlogPostState((p)=>{
        return {...p,titleValue:newValue}
      })
    }
    const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      setBlogPostState((p)=>{
        return {...p,textValue:newValue}
      })
    }
    const onChangeAuthor = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setBlogPostState((p)=>{
        return {...p,authorValue:newValue}
      })
    }
    const addBlog=()=>{
      ref.current.save().then((outputData:any) => {
        console.log('blocks',outputData)
        const dataSendApi:BlogType={
          title:blogPostState.titleValue,
          author:blogPostState.authorValue,
          createdAt:Date.now().toString(),
          bannerurl:blogPostState.photoValue,
          blocks:outputData.blocks,
          description:blogPostState.textValue
        }
        mutate(dataSendApi,{
          onSuccess:()=>{
            navigate('/')
          }
        })
      }).catch((error:any) => {
        console.log('Saving failed: ', error)
      });
      toggleAddBlogModal()
    }
    const toggleAddBlogModal=()=>{
      setShowAddBlogModal((p)=>!p)
    }
    const onPublishButtonPressed=()=>{
      toggleAddBlogModal()
    }
    return <BlogEditorTemplate
    changeAuthor={onChangeAuthor}
    changePhoto={onChangePhoto}
    changeText={onChangeText}
    changeTitle={onChangeTitle}
    blogPostState={blogPostState}
    addPost={addBlog}
    showAddBlogModal={showAddBlogModal}
    toggleAddBlogModal={toggleAddBlogModal}
    onPublishButtonPressed={onPublishButtonPressed}
    />
}
export default BlogEditor;