import React from 'react'

import FeatureCard from '../../common/components/featureCard'
import Question from '../../common/components/question'
import  './home.css'
import NavBar from '../../common/components/navBar'
import Footer from '../../common/components/footer'
import { Form } from "react-bootstrap";
import Contact from '../../common/components/contact'
import { HomePropType } from './home.types'
const Home = (props:HomePropType) => {
  const {userName,navigatoStories}=props
    return (
      <div className="home-container">
        <NavBar userName={userName}/>
        <div className="home-hero">
          <div className="heroContainer home-hero1">
            <div className="home-container01">
              <h1 className="home-hero-heading heading1">
                From Simple Ideas to Million-Dollar Businesses
              </h1>
              <span className="home-hero-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>Bharat Success Story</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
              <div className="home-btn-group">
          {!userName?    <Form.Control
          size="sm"
          type="text"
          placeholder="Enter Your Email"
          className='emailInput'
          style={{width:"300px"}}
        />:undefined}
                <button onClick={navigatoStories} className="buttonFilled">Get Inspired</button>
              </div>
            </div>
          </div>
        </div>
        <div className="home-features">
          <div className="featuresContainer">
            <div className="home-features1">
              <div className="home-container02">
                <span className="overline">
                  <span>features</span>
                  <br></br>
                </span>
                <h2 className="home-features-heading heading2">
                  Unlock the Secrets of Success
                </h2>
                <span className="home-features-sub-heading bodyLarge">
                  <span>
                    <span>
                      <span>
                        Discover how ordinary people achieved extraordinary
                        success with their business ideas
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div className="home-container03">
                <FeatureCard
                  Heading="Inspiring Case Studies"
                  SubHeading="Real stories of ordinary people who turned their simple ideas into multi-million dollar businesses"
                ></FeatureCard>
                <FeatureCard
                  Heading="Million Dollar Revenue"
                  SubHeading="Learn how these entrepreneurs achieved massive success and generated millions in revenue"
                ></FeatureCard>
                <FeatureCard
                  Heading="Simple Ideas, Big Results"
                  SubHeading="Discover how small side projects transformed into thriving businesses with significant impact"
                ></FeatureCard>
                <FeatureCard
                  Heading="Proven Success Strategies"
                  SubHeading="Uncover the strategies and tactics used by these successful individuals to build their empires"
                ></FeatureCard>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="home-pricing">
          <div className="pricingContainer">
            <div className="home-container04">
              <span className="overline">
                <span>Pricing</span>
                <br></br>
              </span>
              <h2 className="heading2">Choose Your Plan</h2>
              <span className="home-pricing-sub-heading bodyLarge">
                <span>
                  <span>Select the perfect plan for your needs</span>
                </span>
              </span>
            </div>
            <div className="home-container05">
              <div className="freePricingCard home-pricing-card">
                <div className="home-container06">
                  <span className="home-text36 heading3">Free</span>
                  <span className="bodySmall">A short description for plan</span>
                </div>
                <div className="home-container07">
                  <span className="home-text37">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-free-plan-price">0</span>
                </div>
                <div className="home-container08">
                  <div className="home-container09">
                    <span className="home-text40">✔</span>
                    <span className="bodySmall">Feature 1 of the Free plan</span>
                  </div>
                  <div className="home-container10">
                    <span className="home-text41">✔</span>
                    <span className="bodySmall">Feature 2 of the Free plan</span>
                  </div>
                  <div className="home-container11">
                    <span className="home-text42">✔</span>
                    <span className="bodySmall">Feature 3 of the Free plan</span>
                  </div>
                  <div className="home-container12">
                    <span className="home-text43">✔</span>
                    <span className="bodySmall">Feature 4 of the Free plan</span>
                  </div>
                </div>
                <button className="home-button buttonOutline">
                  Continue with Free
                </button>
              </div>
              <div className="basicPricingCard home-pricing-card1">
                <div className="home-container13">
                  <span className="home-text44 heading3">BASIC</span>
                  <span className="bodySmall">A short description for plan</span>
                </div>
                <div className="home-container14">
                  <span className="home-text45">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-basic-plan-pricing">10</span>
                  <span className="home-text48">/ month</span>
                </div>
                <div className="home-container15">
                  <div className="home-container16">
                    <span className="home-text49">✔</span>
                    <span className="bodySmall">All features of FREE plan</span>
                  </div>
                  <div className="home-container17">
                    <span className="home-text51">✔</span>
                    <span className="bodySmall">Feature 1 of the Basic plan</span>
                  </div>
                  <div className="home-container18">
                    <span className="home-text52">✔</span>
                    <span className="bodySmall">Feature 2 of the Basic plan</span>
                  </div>
                  <div className="home-container19">
                    <span className="home-text53">✔</span>
                    <span className="bodySmall">Feature 3 of the Basic plan</span>
                  </div>
                  <div className="home-container20">
                    <span className="home-text54">✔</span>
                    <span className="bodySmall">Feature 4 of the Basic plan</span>
                  </div>
                </div>
                <button className="home-button1 buttonFilledSecondary">
                  Try the Basic plan
                </button>
              </div>
              <div className="proPricingCard home-pricing-card2">
                <div className="home-container21">
                  <span className="home-text55 heading3">
                    <span>PRO</span>
                    <br></br>
                  </span>
                  <span className="bodySmall">A short description for plan</span>
                </div>
                <div className="home-container22">
                  <span className="home-text58">
                    <span>$</span>
                    <span></span>
                  </span>
                  <span className="home-pro-plan-pricing">20</span>
                  <span className="home-text61">/ month</span>
                </div>
                <div className="home-container23">
                  <div className="home-container24">
                    <span className="home-text62">✔</span>
                    <span className="bodySmall"> All features of BASIC plan</span>
                  </div>
                  <div className="home-container25">
                    <span className="home-text64">✔</span>
                    <span className="bodySmall">Feature 1 of the Pro plan</span>
                  </div>
                  <div className="home-container26">
                    <span className="home-text65">✔</span>
                    <span className="bodySmall">Feature 2 of the Pro plan</span>
                  </div>
                  <div className="home-container27">
                    <span className="home-text66">✔</span>
                    <span className="bodySmall">Feature 3 of the Pro plan</span>
                  </div>
                  <div className="home-container28">
                    <span className="home-text67">✔</span>
                    <span className="bodySmall">Feature 4 of the Pro plan</span>
                  </div>
                </div>
                <button className="home-button2 buttonFilledSecondary">
                  Try the PRO plan
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="home-banner">
          <div className="bannerContainer home-banner1">
            <h1 className="home-banner-heading heading2">
              Discover How Ordinary People Achieved Extraordinary Success
            </h1>
            <span className="home-banner-sub-heading bodySmall">
              <span>
                <span>
                  <span>
                    Explore our collection of case studies showcasing the
                    incredible journeys of individuals who turned their simple
                    ideas or side projects into multi-million dollar businesses.
                    Learn from their experiences, strategies, and insights to fuel
                    your own entrepreneurial ambitions.
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
              <span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </span>
            </span>
            <button className="buttonFilled">Read More</button>
          </div>
        </div>
        <div className="home-faq">
          <div className="faqContainer">
            <div className="home-faq1">
              <div className="home-container29">
                <span className="overline">
                  <span>FAQ</span>
                  <br></br>
                </span>
                <h2 className="home-text85 heading2">Common questions</h2>
                <span className="home-text86 bodyLarge">
                  <span>
                    Here are some of the most common questions that we get.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="home-container30">
                <Question
                  Answer="You can access the case studies on Bharat Success Story by visiting our website and navigating to the 'Case Studies' section."
                  Question="How can I access the case studies on Bharat Success Story?"
                ></Question>
                <Question
                  Answer="Yes, all the case studies on Bharat Success Story are based on real success stories of ordinary people who have built businesses to millions in revenue."
                  Question="Are the case studies based on real success stories?"
                ></Question>
                <Question
                  Answer="Yes, we welcome submissions of success stories from individuals who have achieved significant business growth. Please visit our 'Submit Your Story' page for more information."
                  Question="Can I submit my own success story to be featured on Bharat Success Story?"
                ></Question>
                <Question
                  Answer="No, accessing the case studies on Bharat Success Story is completely free of charge. Simply visit our website and start exploring the inspiring success stories."
                  Question="Is there a cost to access the case studies on Bharat Success Story?"
                ></Question>
                <Question
                  Answer="For any inquiries or questions, you can reach out to us through the contact form provided on our website. We will get back to you as soon as possible."
                  Question="How can I contact Bharat Success Story for further inquiries?"
                ></Question>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
  

export default Home
